<template>
    <div class="applications">
        <h1 >{{ $t('enrollment.application_manager') }}</h1>
        <div class="controls">
            <div class="sorts">
                <div>
                    <label for="sort_select">{{ $t('enrollment.razeni_zaznamu')+' ' }}</label>
                    <select name="sort_select" id="sort_select" v-model="sortBy" @change="loadData(1);">
                        <option v-for="(option,index) in sortSelect" :key="index" :value="index">{{ option }}</option>
                    </select>
                </div>
                <div>
                    <label for="checkbox_vcetne_vyrazenych">{{ $t('enrollment.vcetne_vyrazenych')+' '  }}</label>
                    <input type="checkbox" name="checkbox_vcetne_vyrazenych" id="checkbox_vcetne_vyrazenych" v-model="vcetne_vyrazenych" @change="loadData(1);" true-value="1"
                                false-value="0"/>
                </div>
            </div>
            <BaseButton @click="openedModal = 'select_report'" :title="$t('controls.report_selection')"><BaseIcon class="print" /></BaseButton>
        </div>
        <LoadedContent  :loaded="isLoaded" :error="isError">
            <BaseTable :columns="columns" :data="data" />
            <PageFooter>
                <BasePagination :getUrl="getPageUrl" :loadData="loadData"/>
                <AddItemButton @click="openedModal = 'add_new'" />
            </PageFooter>
        </LoadedContent>
                 
        <teleport to="#app">
            <ApplicationForm  :opened="openedModal === 'add_new'" @close="closeModal()"/>
            <ModalPdf  :opened="openedModal === 'modal_pdf'" @close="closeModal()" :pdf_url="pdf_url" />
            <ApplicationEditForm  :opened="openedModal === 'edit_record'" @close="closeModal()" :baseData="activeItem" />
            <SelectReport  :opened="openedModal === 'select_report'" @close="closeModal()" @generate_pdf="generate_pdf" />
        </teleport>

    </div>
</template>

<script>
import BaseTable from "@common/components/crud/BaseTable";
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed, defineAsyncComponent, provide, ref } from 'vue';
import LoadedContent from '@common/components/base/LoadedContent';
import { useRouter } from 'vue-router';
import BasePagination from '@common/components/crud/BasePagination';
import AddItemButton from '@admin/components/base/AddItemButton';
import PageFooter from '@admin/components/base/PageFooter';

export default {
    name: "Applications",
    components: {
        BaseTable,
        LoadedContent,
        BasePagination,
        AddItemButton,
        PageFooter,
        ApplicationForm: defineAsyncComponent(() => import(/* webpackChunkName: "application-form" */'@common/components/enrollment/ApplicationForm')),
        ApplicationEditForm: defineAsyncComponent(() => import(/* webpackChunkName: "application-edit-form" */'@admin/components/enrollment/ApplicationEditForm')),
        SelectReport: defineAsyncComponent(() => import(/* webpackChunkName: "select-report" */'@admin/components/enrollment/SelectReport')),
        ModalPdf: defineAsyncComponent(() => import(/* webpackChunkName: "modal-pdf" */'@common/components/base/ModalPdf')),
    },
    setup(){
        provide("crud_store","application")

        const store = useStore();
 
        const i18n = useI18n();

        const router = useRouter();

        
        // loading data
        store.dispatch('application/fetchItems',{});
        store.dispatch('school/fetchItems',{});

        const loadData = (page = 1) => {
            if(router.currentRoute.value.name !== 'applications' && router.currentRoute.value.name !== 'applications_overview'){
                return;
            }
            // let page = 1;
            if(router.currentRoute.value.params.page_num){
                page = router.currentRoute.value.params.page_num;
            } 
            let query = {
                sort: sortBy.value,
                discarded: vcetne_vyrazenych.value,
            };
            store.dispatch('application/fetchItems',{page: page,filter: true,query});
        };  
        // watchEffect(() => loadData());

        const pohlavi = [
            {
                code: "10",
                title: "M - chlapci"
            },
            {
                code: "20",
                title: "F - dívky"
            }
        ]

        const columns = [
            {
                type: "edit_record",
                action: (record) => openModal('edit_record',record),
            },
            { 
                type: "info",
                header: "MŠ",
                key: "schoolProcessingCode",
                title: "MŠ",
                class: "w-s",
                filter_select: true,//schools.value,
                filter_code: 'item_key',
                filter_key: 'schoolProcessing.item_key',
                filter_title: "short_name",
                options_store: 'school/getItems'
            },
            { 
                type: "info",
                header: i18n.t("enrollment.reg_number"),
                filter: true,
                key: "reg_number",
                title:i18n.t("enrollment.reg_number"),
                class: "w-l",
            },
            { 
                type: "info",
                header: i18n.t("enrollment.prijmeni_ditete"),
                filter: true,
                key: "prijmeni_ditete",
                title:i18n.t("enrollment.prijmeni_ditete"),
                class: "w-l",
            },
            { 
                type: "info",
                header: i18n.t("enrollment.jmeno_ditete"),
                filter: false,
                key: "jmeno_ditete",
                title:i18n.t("enrollment.jmeno_ditete"),
                class: "w-l",
            },
            { 
                type: "info",
                header: i18n.t("enrollment.datum_narozeni"),
                filter: false,
                key: "datumNarozeni",
                title:i18n.t("enrollment.datum_narozeni"),
                // content: (record) => {
                //     const dateTime = new Date(record.datum_narozeni);
                //     return dateTime.toLocaleString('cs-CZ',  {year: 'numeric', month: 'numeric', day: 'numeric'});
                // },
                class: "w-l",
            },
            { 
                type: "label",
                header: 'M/F',
                key: "pohlavi",
                title:i18n.t("enrollment.pohlavi_ditete"),
                class: "w-xs",
                filter_select: pohlavi,
                content: (record) => {
                    switch(record.pohlavi){
                        case 10:
                            return "M";
                        case 20:
                            return "F";
                        default: 
                            return "";
                    }
                }
            },
            { 
                type: "info",
                header: i18n.t("enrollment.stari"),
                filter: true,
                filter_exact: true,
                key: "stariDitete",
                title:i18n.t("enrollment.stari"),
                class: "w-xs",
            },
            { 
                type: "label",
                header: 'O',
                key: "priznak_spadova_obec",
                title:i18n.t("enrollment.spadova_obec"),
                class: "w-xs",
                filter_select: [
                    {
                        code: '1',
                        title: i18n.t("enrollment.spadova_obec")
                    }
                ],
                content: (record) => {
                    let priznak = record.priznak_spadova_obec;
                    if(typeof priznak === 'string'){
                        priznak = parseInt(priznak);
                    }
                    if(priznak){
                        return 'O';
                    } else {
                        return '';
                    }
                }
            },
            { 
                type: "label",
                header: 'P',
                key: "priznak_predskolak",
                title: i18n.t("enrollment.predskolak"),
                class: "w-xs",
                filter_select: [
                    {
                        code: '1',
                        title: i18n.t("enrollment.predskolak")
                    }
                ],
                content: (record) => {
                    let priznak = record.priznak_predskolak;
                    if(typeof priznak === 'string'){
                        priznak = parseInt(priznak);
                    }
                    if(priznak){
                        return 'P';
                    } else {
                        return '';
                    }
                }
            },
            { 
                type: "label",
                header: 'S',
                key: "priznak_sourozenci",
                title:i18n.t("enrollment.sourozenec"),
                class: "w-xs",
                filter_select: [
                    {
                        code: '1',
                        title: i18n.t("enrollment.sourozenec")
                    }
                ],
                content: (record) => {
                    let priznak = record.priznak_sourozenci;
                    if(typeof priznak === 'string'){
                        priznak = parseInt(priznak);
                    }
                    if(priznak){
                        return 'S';
                    } else {
                        return '';
                    }
                }
            },
            { 
                type: "label",
                header: i18n.t("enrollment.application_status"),
                key: "status",
                title:i18n.t("enrollment.application_status"),
                class: "w-l",
                filter_select: [
                    {
                        code: '0',
                        title: i18n.t("enrollment.nova_zadost")
                    },
                    {
                        code: '1',
                        title: i18n.t("enrollment.zadost_v_rizeni")
                    },
                    {
                        code: '2',
                        title: i18n.t("enrollment.dite_prijato")
                    },
                    {
                        code: '3',
                        title: i18n.t("enrollment.dite_neprijato")
                    },
                    {
                        code: '9',
                        title: i18n.t("enrollment.vyrazena_zadost")
                    },
                ],
                content: (record) => {
                    switch(record.status){
                        case 0:
                            return i18n.t("enrollment.nova_zadost_short");
                        case 1:
                            return i18n.t("enrollment.zadost_v_rizeni_short");
                        case 2:
                            return i18n.t("enrollment.dite_prijato_short");
                        case 3:
                            return i18n.t("enrollment.dite_neprijato_short");
                        case 9:
                            return i18n.t("enrollment.vyrazena_zadost_short");
                        default: 
                            return "";
                    }
                }
            },
            {
                type: 'button',
                icon: () => 'preview',
                content: () => '',
                title: i18n.t("enrollment.opis_zadosti"),
                action: (record) => {
                    generate_pdf(`generate-transcript?code=${record.code}`);
                },
            }

        ];

          // getting data
        const data = computed(() => store.getters['application/getItems']);

        const isLoaded = computed(() => store.getters["application/isLoaded"]);
        
        const isError = computed(() => store.getters["application/isError"]);

        // modal window
        const activeItem = ref(null);
        const openedModal = ref(null);
        const pdf_url = ref('');
        const openModal = (modal,record = null,url = '') => {
            openedModal.value = modal;
            activeItem.value = record;
            pdf_url.value = url;
        }
        const closeModal = () => {
            openedModal.value = null;
            activeItem.value = null;
            pdf_url.value = '';
        }

        // pagination
        const getPageUrl = (number) => {
            return 'url.project/:project_code/url.sprava_zadosti/url.overview/!'+number;
        }

        const sortSelect = ref({
            0: i18n.t("enrollment.podle_kriterii"),
            1: i18n.t("enrollment.podle_jmena"),
        });

        const sortBy = ref(0);
        const vcetne_vyrazenych = ref(false);

        const generate_pdf = (action,filtered = false, params = {}) => {
            let url = router.currentRoute.value.params.lang+`/`+router.currentRoute.value.params.project_code+`/v1/enrollment/report/`+action;
            if(filtered){
                url += '?';
                const filters = store.getters['application/getFilters'];
                for (let key in filters) {
                    if (!Object.prototype.hasOwnProperty.call(filters,key)) continue; // to remove js object default properties

                    if(filters[key].exact){
                        url += `filter[${key}]=${filters[key].value}&`;
                    } else {
                        url += `filter[${key}][like]=${filters[key].value}&`;
                    }
                    
                }
                const query = store.getters['application/getQuery'];
                for (let key in query) {
                    if (!Object.prototype.hasOwnProperty.call(query,key)) continue; // to remove js object default properties

                    url += `${key}=${query[key]}&`;
                }
                for (let key in params) {
                    if (!Object.prototype.hasOwnProperty.call(params,key)) continue; // to remove js object default properties

                    url += `${key}=${params[key]}&`;
                }
                url = url.slice(0,-1);
            }
            // console.log(url)
            openModal('modal_pdf',null,url);
        }


        return {
            columns,
            data,
            isLoaded,
            isError,
            closeModal,
            activeItem,
            openModal,
            getPageUrl,
            openedModal,
            loadData,
            pdf_url,
            sortSelect,
            vcetne_vyrazenych,
            sortBy,
            generate_pdf
        }

    }
}
</script>

<style scoped>
.applications,.loaded-content{
    display: flex;
    flex-direction: column;
    height: 100%;
}
::v-deep(.base-table){
    margin: 1rem;
    border-collapse: separate;
    border-spacing: 0 0.2rem;
    min-width: initial;
}
::v-deep(.base-table-row:nth-child(odd) td) {
    background: #fff6e6;
}

::v-deep(.base-table-row:nth-child(even) td) {
    background: #ffe7bb;
}
::v-deep(.base-table-row td) {
    padding: 0.5rem 0.2rem;
}
::v-deep(.base-table-row td.no-padding) {
    padding: 0;
}
::v-deep(.base-table td:first-child) { 
    border-top-left-radius: 1rem; 
    border-bottom-left-radius: 1rem;
    padding: 0.5rem 0.2rem 0.5rem 0.5rem;
}
::v-deep(.base-table td:last-child) { 
    border-top-right-radius: 1rem; 
    border-bottom-right-radius: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 0.2rem;
}
::v-deep(.loader .base-loader) {
    left: 2rem;
    top:0;
}
::v-deep(.icon.preview){
    width: 100%;
    height: 100%;
}
.controls{
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}
.controls .btn{
    width: 2.5rem;
    height: 2.5rem; 
    padding: 0.3rem;
}
.controls .sorts{
    margin: 0 0.5rem;
    font-size: 0.8rem;
    text-align: right;
}
</style>